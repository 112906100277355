/* eslint-disable no-undef */
module.exports = {
  mode: 'jit',
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  content: ['./src/**/*.{js,jsx,ts,tsx,css}', './public/index.html'],
  theme: {
    extend: {
      fontFamily: {
        sans: 'var(--font-sans)',
        signature: 'var(--font-signature)',
      },
      fontSize: {
        'dpm-48': '3rem',
        'dpm-40': '2.5rem',
        'dpm-32': '2rem',
        'dpm-25': '1.5625rem',
        'dpm-20': '1.25rem',
        'dpm-18': '1.1111rem',
        'dpm-16': '1rem',
        'dpm-14': '0.872rem',
        'dpm-12': '0.833rem',
      },
      zIndex: {
        'left-nav': 9999,
        'modal-backdrop': 400,
        modal: 500,
        toast: 1000,
        '-popover-1': 1400,
        popover: 1500,
        'popover-1': 1600,
      },
      minWidth: {
        px: '1px',
        0: '0',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        32: '8rem',
        40: '10rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
        128: '32rem',
        'dropdown-box': '14.5rem',
        'step-card': '18.75rem',
      },
      maxWidth: (theme) => ({
        0: '0',
        content: `calc(100vw - ${theme('spacing')['56']} - ${theme('spacing')['10']})`,
        'step-card': '18.75rem',
      }),
      spacing: {
        'auth-sidebar': '25vw',
      },
      inset: {
        '1/2': '50%',
        '1/4': '25%',
        '3/4': '75%',
        '-1/2': '-50%',
        '-1/4': '-25%',
        '-3/4': '-75%',
      },
      keyframes: {
        'slide-in-from-right': {
          '0%': { transform: 'translateX(100%)', opacity: '0' },
          '100%': { transform: 'translateX(0)', opacity: '1' },
        },
        'slide-out-to-right': {
          '0%': { transform: 'translateX(0)', opacity: '1' },
          '100%': { transform: 'translateX(100%)', opacity: '0' },
        },
        'fade-out-scale': {
          from: { opacity: '1', transform: 'scale(1)' },
          to: { opacity: '0', transform: 'scale(0.95)' },
        },
        'fade-in-scale': {
          from: { opacity: '0', transform: 'scale(0.95)' },
          to: { opacity: '1', transform: 'scale(1)' },
        },
      },
      animation: {
        'spin-reverse': '1s linear 0s infinite reverse both running spin',
        'toast-in-top-right':
          'toast-in-common 750ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal both running, toast-in-transform-top-right 750ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal both running',
        'toast-out-top-right':
          'toast-out-common 5000ms linear 0s 1 normal both running, toast-out-transform-top-right 5000ms linear 0s 1 normal both running',
        'toast-in-top-center':
          'toast-in-common 750ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal both running, toast-in-transform-top-center 750ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal both running',
        'toast-out-top-center':
          'toast-out-common 5000ms linear 0s 1 normal both running, toast-out-transform-top-center 5000ms linear 0s 1 normal both running',
        'bounce-short': 'bounce 0.7s ease-in-out 1.5',
        'slide-in-from-right': 'slide-in-from-right 0.3s ease-out',
        'slide-out-to-right': 'slide-out-to-right 0.3s ease-in forwards',
        'fade-in-scale': 'fade-in-scale 0.3s ease-out',
        'fade-out-scale': 'fade-out-scale 0.3s ease-in forwards',
      },
      backgroundImage: {
        logo: 'var(--image-logo)',
      },
      colors: {
        primary: {
          1: 'rgba(var(--color-primary-1), 1)',
          2: 'rgba(var(--color-primary-2), 1)',
        },
        accent: {
          1: 'rgba(var(--color-accent-1), 1)',
          2: 'rgba(var(--color-accent-2), 1)',
          3: 'rgba(var(--color-accent-3), 1)',
          4: 'rgba(var(--color-accent-4), 1)',
          'light-mid': 'rgba(var(--color-accent-light-mid), 1)',
          'light-5': 'rgba(var(--color-accent-light-5), 1)',
        },
        semantic: {
          1: 'rgba(var(--color-semantic-1), 1)',
          2: 'rgba(var(--color-semantic-2), 1)',
          3: 'rgba(var(--color-semantic-3), 1)',
          4: 'rgba(var(--color-semantic-4), 1)',
          5: 'rgba(var(--color-semantic-5), 1)',
          alert: 'rgba(var(--color-semantic-alert), 1)',
        },
        'semantic-light': {
          1: 'rgba(var(--color-semantic-light-1), 1)',
          2: 'rgba(var(--color-semantic-light-2), 1)',
          3: 'rgba(var(--color-semantic-light-3), 1)',
          4: 'rgba(var(--color-semantic-light-4), 1)',
        },
        gray: {
          1: 'rgba(var(--color-gray-1), 1)',
          2: 'rgba(var(--color-gray-2), 1)',
          3: 'rgba(var(--color-gray-3), 1)',
          4: 'rgba(var(--color-gray-4), 1)',
          5: 'rgba(var(--color-gray-5), 1)',
          6: 'rgba(var(--color-gray-6), 1)',
        },
        'light-gray': {
          1: 'rgba(var(--color-light-gray-1), 1)',
        },
        color: {
          1: 'rgba(var(--color-text-1), 1)',
          2: 'rgba(var(--color-text-2), 1)',
          3: 'rgba(var(--color-text-3), 1)',
          4: 'rgba(var(--color-text-4), 1)',
          'on-first-primary': {
            1: 'rgba(var(--color-text-on-first-primary-1), 1)',
            2: 'rgba(var(--color-text-on-first-primary-2), 1)',
            3: 'rgba(var(--color-text-on-first-primary-3), 1)',
            4: 'rgba(var(--color-text-on-first-primary-4), 1)',
          },
        },
        background: {
          1: 'rgba(var(--color-background-1), 1)',
          2: 'rgba(var(--color-background-2), 1)',
          sidebar: 'rgba(var(--color-background-sidebar), 1)',
        },
        'progress-bar': {
          1: 'rgba(var(--color-progress-bar-1), 1)',
          2: 'rgba(var(--color-progress-bar-2), 1)',
          3: 'rgba(var(--color-progress-bar-3), 1)',
        },
        'status-tag': {
          1: 'rgba(var(--color-status-tag-1), 1)',
          2: 'rgba(var(--color-status-tag-2), 1)',
          3: 'rgba(var(--color-status-tag-3), 1)',
          4: 'rgba(var(--color-status-tag-4), 1)',
          5: 'rgba(var(--color-status-tag-5), 1)',
          6: 'rgba(var(--color-status-tag-6), 1)',
        },
        link: {
          1: 'rgba(var(--color-link-1), 1)',
        },
        dashboard: {
          1: 'rgb(var(--color-dashboard-1))',
          2: 'rgb(var(--color-dashboard-2))',
          3: 'rgb(var(--color-dashboard-3))',
          4: 'rgb(var(--color-dashboard-4))',
        },
        button: {
          primary: {
            default: 'rgb(var(--color-button-primary-default))',
            pressed: 'rgb(var(--color-button-primary-pressed))',
            disabled: 'rgb(var(--color-button-primary-disabled))',
          },
          secondary: {
            default: 'rgb(var(--color-button-secondary-default))',
            pressed: 'rgb(var(--color-button-secondary-pressed))',
            disabled: 'rgb(var(--color-button-secondary-disabled))',
          },
          toggle: {
            selected: 'rgb(var(--color-button-toggle-selected))',
            deselected: 'rgb(var(--color-button-toggle-deselected))',
            disabled: 'rgb(var(--color-button-toggle-disabled))',
          },
        },
      },
      backgroundColor: {
        primary: {
          1: 'rgba(var(--color-primary-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-primary-2), var(--tw-bg-opacity, 1))',
        },
        semantic: {
          1: 'rgba(var(--color-semantic-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-semantic-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-semantic-3), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-semantic-4), var(--tw-bg-opacity, 1))',
          5: 'rgba(var(--color-semantic-5), var(--tw-bg-opacity, 1))',
          alert: 'rgba(var(--color-semantic-alert), var(--tw-bg-opacity, 1))',
        },
        'semantic-light': {
          1: 'rgba(var(--color-semantic-light-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-semantic-light-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-semantic-light-3), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-semantic-light-4), var(--tw-bg-opacity, 1))',
          5: 'rgba(var(--color-semantic-light-5), var(--tw-bg-opacity, 1))',
        },
        gray: {
          1: 'rgba(var(--color-gray-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-gray-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-gray-3), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-gray-4), var(--tw-bg-opacity, 1))',
          5: 'rgba(var(--color-gray-5), var(--tw-bg-opacity, 1))',
        },
        color: {
          1: 'rgba(var(--color-text-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-text-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-text-3), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-text-4), var(--tw-bg-opacity, 1))',
          'on-first-primary': {
            1: 'rgba(var(--color-text-on-first-primary-1), var(--tw-bg-opacity, 1))',
            2: 'rgba(var(--color-text-on-first-primary-2), var(--tw-bg-opacity, 1))',
            3: 'rgba(var(--color-text-on-first-primary-3), var(--tw-bg-opacity, 1))',
            4: 'rgba(var(--color-text-on-first-primary-4), var(--tw-bg-opacity, 1))',
          },
        },
        background: {
          1: 'rgba(var(--color-background-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-background-2), var(--tw-bg-opacity, 1))',
        },
        'progress-bar': {
          1: 'rgba(var(--color-progress-bar-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-progress-bar-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-progress-bar-3), var(--tw-bg-opacity, 1))',
        },
        'status-tag': {
          1: 'rgba(var(--color-status-tag-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-status-tag-2), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-status-tag-3), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-status-tag-4), var(--tw-bg-opacity, 1))',
        },
        dashboard: {
          1: 'rgba(var(--color-dashboard-1), var(--tw-bg-opacity, 1))',
          2: 'rgba(var(--color-dashboard-1), var(--tw-bg-opacity, 1))',
          3: 'rgba(var(--color-dashboard-1), var(--tw-bg-opacity, 1))',
          4: 'rgba(var(--color-dashboard-4), var(--tw-bg-opacity, 1))',
        },
        link: {
          1: 'rgba(var(--color-link-1), var(--tw-bg-opacity, 1))',
        },
      },
      minHeight: {
        px: '1px',
        0: '0',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        32: '8rem',
        40: '10rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        80: '20rem',
        128: '32rem',
      },
      maxHeight: {
        px: '1px',
        0: '0',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        32: '8rem',
        40: '10rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        '1/6': '16.66%',
        '2/6': '33.33%',
        '3/6': '50%',
        '4/6': '66.66%',
        '5/6': '83.33%',
        '6/6': '100%',
      },
      borderRadius: {
        'dpm-sm': '0.1875rem',
        'dpm-md': '0.3125rem',
      },
      height: {
        '1/2': '50%',
        '1/3': '33.33%',
        '2/3': '66.66%',
        'dashboard-row-1': '7.1rem',
        'dashboard-row-2': '10rem',
        'dashboard-row-3': '25rem',
        'dashboard-row-4': '41rem', // doesn't fit in with the rest because of how gap works
      },
      width: {
        128: '32rem',
        '1/8': '12.5%',
        '2/8': '25%',
        '3/8': '37.5%',
        '4/8': '50%',
        '5/8': '62.5%',
        '6/8': '75%',
        '7/8': '87.5%',
        '8/8': '100%',
        'step-card': '17.5rem',
      },
      screens: {
        '4k': { min: '3840px' },
        xl: '1300px',
      },
    },
  },
  variants: {
    borderColor: ['responsive', 'hover', 'focus', 'focus-within', 'disabled'],
    zIndex: ['hover', 'responsive'],
    textColor: ['responsive', 'hover', 'disabled'],
    margin: ['responsive', 'hover'],
  },
  plugins: [],
};
