/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeStateProps } from '../global-state/Theme';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';

const memoize = <T>(fn: (arg: any) => T): ((arg: any) => T) => {
  const cache = new Map();
  return (key) => {
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = fn(key);
    cache.set(key, result);
    return result;
  };
};

class ThemeUtils {
  /**
   * Inserts/updates the font tags in the head of the document
   * @param currentTheme Theme in state
   */
  private static setFont(currentTheme: ThemeStateProps) {
    if (currentTheme.assets.fonts) {
      for (const [key, href] of Object.entries(currentTheme.assets.fonts)) {
        const fontLink = document.querySelector(`link#${key}`);
        if (fontLink) {
          fontLink.setAttribute('href', href);
        } else {
          const link = document.createElement('link');
          link.setAttribute('id', key);
          link.setAttribute('rel', 'stylesheet');
          link.setAttribute('href', href);
          document.head.appendChild(link);
        }
      }
    }
  }

  /**
   * Inserts/updates the <style id="app-theme"> tag in the head of the document
   * @param currentTheme Theme in state
   */
  private static setStyle(themeCSS: string) {
    const themeStyleElement = document.querySelector(`style#app-theme`);
    if (themeStyleElement) {
      themeStyleElement.innerHTML = themeCSS;
    } else {
      const style = document.createElement('style');
      style.setAttribute('id', 'app-theme');
      style.innerHTML = themeCSS;
      document.head.appendChild(style);
    }
  }

  /**
   * Builds CSS from the currentTheme object & sets it in the appropriate <style> tag. Also imports the theme's font via appropriate <link>.
   * @param currentTheme The theme currently set in state
   */
  public static applyTheme(currentTheme: ThemeStateProps): void {
    const themeCSS = ThemeUtils.buildCss(currentTheme.styles);
    ThemeUtils.setStyle(themeCSS);
    ThemeUtils.setFont(currentTheme);
  }

  /**
   * Converts the current theme object to a CSS string to be inserted into a <style> tag in the head of the document.
   * @param currentTheme The theme currently set in state
   */
  public static buildCss(theme: Record<string, unknown> | null): string {
    if (!theme) return '';

    /**
     * Returns new CSS lines to add to the theme CSS. This fn serves to loop through child objects & recursively add CSS lines.
     * @param rootKey The CSS property root key (e.g. --color-primary)
     * @param obj The child object { 1: '255, 255, 255', 2: '0, 0, 0' }
     * @returns `
     * --color-primary-1: 255,255,255;
     * --color-primary-2: 0,0,0;
     * `
     */
    const recursivelyAddCSSLines = (rootKey: string, obj: Record<string, unknown>): string => {
      let cssLine = '';
      Object.entries(obj as Record<string, unknown>).forEach(([key, value]) => {
        cssLine += addCSSLines(rootKey, key, value);
      });
      return cssLine;
    };

    /**
     * Returns new CSS lines to add to the theme CSS
     * @param rootKey Root property e.g. --color
     * @param key Key of new CSS property e.g. primary
     * @param value Value of new CSS property e.g. { 1: '255, 255, 255', 2: '0, 0, 0' }
     * @returns `
     * --color-primary-1: 255,255,255;
     * --color-primary-2: 0,0,0;
     * `
     */
    const addCSSLines = (rootKey: string, key: string, value: string | unknown) => {
      const subKey = rootKey + `-${key}`;
      if (value === null) return;
      if (typeof value === 'string') {
        return `${subKey}: ${value};\n`;
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        return recursivelyAddCSSLines(subKey, value as Record<string, unknown>);
      }
      return '';
    };

    let cssString = '';

    Object.entries(theme).forEach(([category, items]) => {
      const rootKey = `--${category}`;
      Object.entries(items as Record<string, string>).forEach(([key, value]) => {
        cssString += addCSSLines(rootKey, key, value);
      });
    });

    return `
		:root { 
			${cssString}
		}`;
  }

  private static getWidthInternal(size: number): string {
    const tailwind = resolveConfig(tailwindConfig);
    return tailwind.theme.width[size];
  }

  /**
   * Returns the width from the tailwind theme
   * @param size The size of the width
   * @returns The width in string format
   */
  public static getWidth = memoize(this.getWidthInternal);
}

export default ThemeUtils;
