import { create } from 'zustand';

type Store<TValue> = {
  value: TValue;
  setValue: (newValue: TValue | ((prev: TValue) => TValue)) => void;
};

export const simpleGlobalState = <TValue>(initialValue: TValue) =>
  create<Store<TValue>>((set, get) => ({
    value: initialValue,
    setValue: (newValue) => {
      if (newValue instanceof Function) {
        set({ value: newValue(get().value) });
        return;
      }

      set({ value: newValue });
    },
  }));
